/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import './assets/styles/theme.scss';

@import './assets/styles/components/button.scss';
@import './assets/styles/components/info-bubble.scss';
@import './assets/styles/components/page-structure.scss';
@import './assets/styles/components/scroll-bar.scss';
@import './assets/styles/components/table.scss';
@import './assets/styles/components/terms.scss';

html, body {
    height: 100%;
    margin: 0px;
    background: var(--background);
    color: var(--primary);
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Ubuntu, "Helvetica Neue", sans-serif;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

li {
  list-style-type: none;
}


.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}
