@use '@angular/material' as mat;

$cyan-palette: (
    50 : #ebf4f5,
    100 : #cee4e6,
    200 : #add3d6,
    300 : #8cc1c6,
    400 : #73b3b9,
    500 : #5aa6ad,
    600 : #529ea6,
    700 : #48959c,
    800 : #3f8b93,
    900 : #2e7b83,
    A100 : #caf9ff,
    A200 : #97f4ff,
    A400 : #64efff,
    A700 : #4becff,
    contrast: (
        50 : #202325,
        100 : #202325,
        200 : #202325,
        300 : #202325,
        400 : #202325,
        500 : #202325,
        600 : #202325,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #202325,
        A200 : #202325,
        A400 : #202325,
        A700 : #202325,
    )
);

$midnight-green: (
    50 : #e2eaea,
    100 : #b6cbcc,
    200 : #86a8aa,
    300 : #558587,
    400 : #306b6e,
    500 : #0c5154,
    600 : #0a4a4d,
    700 : #084043,
    800 : #06373a,
    900 : #032729,
    A100 : #63f3ff,
    A200 : #30f0ff,
    A400 : #00eafc,
    A700 : #00d2e3,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$light-blue: (
    50 : #e0f5f5,
    100 : #b3e6e6,
    200 : #80d5d6,
    300 : #4dc4c6,
    400 : #26b7b9,
    500 : #00aaad,
    600 : #00a3a6,
    700 : #00999c,
    800 : #009093,
    900 : #007f83,
    A100 : #b0fcff,
    A200 : #7dfbff,
    A400 : #4af9ff,
    A700 : #30f8ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$dark-palette: (
    50 : #e4e5e5,
    100 : #bcbdbe,
    200 : #909192,
    300 : #636566,
    400 : #414446,
    500 : #202325,
    600 : #1c1f21,
    700 : #181a1b,
    800 : #131516,
    900 : #0b0c0d,
    A100 : #0b0c0d,
    A200 : #0b0c0d,
    A400 : #0b0c0d,
    A700 : #0b0c0d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$light-palette: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #eeeeee,
    700 : #dddddd,
    800 : #cccccc,
    900 : #bbbbbb,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// $my-primary: mat-pallette($blue-palette);
// $my-accent: mat-pallette($orange-palette);

$primary: mat.define-palette($light-blue);
$accent: mat.define-palette($midnight-green);
$accent2: mat.define-palette($cyan-palette);
$dark: mat.define-palette($dark-palette);
$light: mat.define-palette($light-palette);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette(mat.$red-palette);

// The "warn" palette is optional and defaults to red if not specified.

$homewellness-theme: mat.define-light-theme(
    $primary,
    $accent,
    $warn
);

@include mat.all-legacy-component-themes($homewellness-theme);

// or any global you wish to share with components 
:root {
    --primary-100: #{mat.get-color-from-palette($primary, 100)};
    --primary-200: #{mat.get-color-from-palette($primary, 200)};
    --primary-300: #{mat.get-color-from-palette($primary, 300)};
    --primary-400: #{mat.get-color-from-palette($primary, 400)};
    --primary: #{mat.get-color-from-palette($primary, 500)};
    --primary-600: #{mat.get-color-from-palette($primary, 600)};
    --primary-700: #{mat.get-color-from-palette($primary, 700)};
    --primary-800: #{mat.get-color-from-palette($primary, 800)};
    --primary-900: #{mat.get-color-from-palette($primary, 900)};
    --primary-contrast: #{mat.get-color-from-palette($primary, default-contrast)};

    --accent-100: #{mat.get-color-from-palette($accent, 100)};
    --accent-200: #{mat.get-color-from-palette($accent, 200)};
    --accent-300: #{mat.get-color-from-palette($accent, 300)};
    --accent-400: #{mat.get-color-from-palette($accent, 400)};
    --accent: #{mat.get-color-from-palette($accent, 500)};
    --accent-600: #{mat.get-color-from-palette($accent, 600)};
    --accent-700: #{mat.get-color-from-palette($accent, 700)};
    --accent-800: #{mat.get-color-from-palette($accent, 800)};
    --accent-900: #{mat.get-color-from-palette($accent, 900)};
    --accent-contrast: #{mat.get-color-from-palette($accent, default-contrast)};

    --accent2-100: #{mat.get-color-from-palette($accent2, 100)};
    --accent2-200: #{mat.get-color-from-palette($accent2, 200)};
    --accent2-300: #{mat.get-color-from-palette($accent2, 300)};
    --accent2-400: #{mat.get-color-from-palette($accent2, 400)};
    --accent2: #{mat.get-color-from-palette($accent2, 500)};
    --accent2-600: #{mat.get-color-from-palette($accent2, 600)};
    --accent2-700: #{mat.get-color-from-palette($accent2, 700)};
    --accent2-800: #{mat.get-color-from-palette($accent2, 800)};
    --accent2-900: #{mat.get-color-from-palette($accent2, 900)};
    --accent2-contrast: #{mat.get-color-from-palette($accent2, default-contrast)};

    --background-100: #{mat.get-color-from-palette($light, 100)};
    --background-200: #{mat.get-color-from-palette($light, 200)};
    --background-300: #{mat.get-color-from-palette($light, 300)};
    --background-400: #{mat.get-color-from-palette($light, 400)};
    --background: #{mat.get-color-from-palette($light, 500)};
    --background-600: #{mat.get-color-from-palette($light, 600)};
    --background-700: #{mat.get-color-from-palette($light, 700)};
    --background-800: #{mat.get-color-from-palette($light, 800)};
    --background-900: #{mat.get-color-from-palette($light, 900)};
    --background-contrast: #{mat.get-color-from-palette($light, default-contrast)};
}