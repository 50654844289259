.mat-table {
    background: var(--background);
    color: var(--primary);

    th, td {
        color: var(--primary);
        border-color: var(--primary);
    }
}

table{
    margin-right: 20px;
    margin-bottom: 20px;
    width: 100%;

    th {
        font-weight: 500;
    }
    td {
        font-weight: 300;
    }
}