body::-webkit-scrollbar {
    width: 10px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: var(--accent2)  var(--background-700);
}

body::-webkit-scrollbar-track {
    background:  var(--background-700);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--accent2);
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--background-700);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var(--accent2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    filter: brightness(20%);
}