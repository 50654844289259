.section-container {

    .section-title {
        color: var(--accent);
        margin-bottom: 5px;
        font-size: 20px;
    }

    .section-title-border {
        border-bottom: 1px solid var(--accent);
        opacity: 0.3;
        margin-bottom: 15px;
    }
}